import React from "react"
import styled from "@emotion/styled"
import Container from "../components/container"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialLinks from "../components/social-links"

const Page = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: ${props => props.theme.spacers.lg};

  .social-link {
    text-align: center;
  }

  .social-logo {
    width: 60px;
    transition: all 0.3s;
    fill: ${props => props.theme.colors.white};
  }

  .social-link a:first-of-type {
    margin-bottom: ${props => props.theme.spacers.base};
  }

  ul.unstyled {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;
    align-content: stretch;
    justify-content: stretch;
    gap: ${props => props.theme.spacers.lg};
    width: 100%;

    @media (min-width: 1000px) {
      min-height: 600px;
    }

    @media (min-width: 1000px) {
      grid-auto-rows: 250px;
      width: 60%;
    }
  }

  li.unstyled {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
    transition: background-image 0.3s;
    background-image: linear-gradient(
      60deg,
      rgba(86, 177, 212, 0.6) 0%,
      rgba(132, 116, 144, 0.7) 100%
    );
    background-color: ${props => props.theme.colors.gray};
    padding: ${props => props.theme.spacers.lg};

    @media (min-width: 1000px) {
      height: 100%;
    }

    &:hover {
      background-image: linear-gradient(
        60deg,
        rgba(60, 132, 160, 1) 0%,
        rgba(118, 108, 125, 0.9) 100%
      );
    }
  }
`
const Heading = styled.h1`
  @media (min-width: 1000px) {
    margin-top: 0;
  }
`

const ContactPage = () => {
  return (
    <Layout>
      <SEO title="Contact" path="/contact/" />
      <Page>
        <Heading>Get in Touch</Heading>
        <p>Find me on:</p>
        <SocialLinks includeLabels />
      </Page>
    </Layout>
  )
}

export default ContactPage
